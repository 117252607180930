import React, {useMemo, Fragment, useEffect, useState} from 'react';
import {
  useTable,
  Column,
  useSortBy,
  useFilters,
  Row,
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
  HeaderGroup,
  useResizeColumns,
} from 'react-table';
import {useSticky} from 'react-table-sticky';
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineX,
  HiSortAscending,
  HiSortDescending,
} from 'react-icons/hi';
import _ from 'lodash';
import {Duration} from 'luxon';
import {useQuery, useQueryClient} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import cx from 'classnames';

import {DatasetStudy, Dataset} from '../../../models/dataset';
import {ColumnDropdown} from '../../../core/components/dropdown';
import {
  CheckboxFilter,
  TextFilter,
  MinMaxFilter,
} from '../../../core/components/react-table';
import {Badge} from '../../../core/components/badge';
import {
  StudyTag,
  getMatchedTags,
  Tag as TagInterface,
} from '../../../models/tags';
import {Tag} from '../../../components/tags';
import {fetchDICOMHeaders} from '../../../models/dicom-header';
import {Pagination} from '../../../core/components/pagination';
import {
  ReportSelectionEventType,
  useReportSelection,
} from '../../../hooks/report-selection-provider';
import {MenuDropdownSingle} from '../../../core/components/menu';
import {ReportComponent} from '../../../components/report';
import {fetchReport, truncateStudyID} from '../../../models/report';
import {Loading} from '../../../core/components/loading';
import {
  fetchDatasetDicomInfo,
  getDicomSeries,
} from '../../../models/dicom-viewer';
import {useAxios} from 'src/utils/http';

export const MetadataTable = ({
  studies,
  datasets,
  datasetId,
  hideSelect = false,
  hideOrderApproval = false,
  visibleDicomHeaders,
  studyTags,
  userTags,
  className,
  splitView = false,
}: {
  studies: DatasetStudy[];
  datasets?: Dataset[];
  datasetId: number;
  hideSelect?: boolean;
  hideOrderApproval?: boolean;
  visibleDicomHeaders: string[];
  studyTags?: StudyTag[];
  userTags?: TagInterface[];
  className?: string;
  splitView?: boolean;
}) => {
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const api = useAxios();

  const [shiftKeyDown, setShiftKeyDown] = useState(false);
  const [lastClickedIndex, setLastClickedIndex] = useState(-1);
  const [openedStudyID, openedStudyIDChange] = useState<string>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', openedStudyID],
    () => {
      return fetchReport(api, openedStudyID as string);
    },
    {
      enabled: !_.isEmpty(openedStudyID),
      keepPreviousData: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  useEffect(() => {
    window.addEventListener('keydown', e => {
      setShiftKeyDown(e.shiftKey);
    });
    window.addEventListener('keyup', e => {
      setShiftKeyDown(e.shiftKey);
    });
  }, []);

  const {data: dicomHeaders} = useQuery(
    ['dicomHeaders'],
    () => fetchDICOMHeaders(api),
    {
      staleTime: Infinity,
    }
  );

  const {data: datasetDicomInfo} = useQuery(
    ['datasetDicom', datasetId],
    () => {
      return fetchDatasetDicomInfo(api, datasetId!);
    },
    {
      enabled: !_.isNil(datasetId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const stickyColumnIDs = [
    'expand',
    'index',
    'selected',
    'approval',
    'studyId',
    'viewDicom',
  ];
  const columns = useMemo<Array<Column<DatasetStudy>>>(
    () => [
      {
        id: 'table',
        sticky: 'left',
        Header: '',
        disableResizing: true,
        getResizerProps: () => {},
        columns: [
          {
            id: 'expand',
            width: 65,
            Header: '',
            accessor: _.constant(null),
            disableSortBy: true,
            disableFilters: true,
            disableResizing: true,
            getResizerProps: () => {},
            Cell: ({row}: {row: Row<DatasetStudy>}) => {
              return (
                <>
                  {!_.isEmpty(row.original.metadata?.series) && (
                    <span
                      className="inline-block align-middle"
                      {...row.getToggleRowExpandedProps()}
                    >
                      {row.isExpanded ? (
                        <HiOutlineChevronUp />
                      ) : (
                        <HiOutlineChevronDown />
                      )}
                    </span>
                  )}
                </>
              );
            },
          },
          {
            id: 'index',
            width: 100,
            Header: '#',
            disableResizing: true,
            getResizerProps: () => {},
            Cell: () => {
              return <></>;
            },
            disableSortBy: true,
            disableFilters: true,
          },
          {
            id: 'selected',
            width: 65,
            disableResizing: true,
            getResizerProps: () => {},
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleAllRowsSelected,
              filteredRows,
              page,
            }) => {
              const {indeterminate: pageIndeterminate, ...pageRestProps} =
                getToggleAllPageRowsSelectedProps();

              return (
                <div className="flex items-center">
                  <input
                    id="toggleAllPageRowsSelected"
                    type="checkbox"
                    className="checkbox-input"
                    data-cy="selectAllStudies"
                    ref={el =>
                      el && (el.indeterminate = pageIndeterminate ?? false)
                    }
                    {...pageRestProps}
                  />
                  <div>
                    <MenuDropdownSingle
                      buttonText=""
                      useDefaultButtonClass={false}
                      buttonClassName="px-2"
                      align="left"
                      menuButtonDisabled={filteredRows.length === 0}
                      menuItems={[
                        {
                          itemText: () => (
                            <div>Select all {page.length} on page</div>
                          ),
                          onClick: () =>
                            document
                              .getElementById('toggleAllPageRowsSelected')
                              ?.click(),
                        },
                        {
                          itemText: () => (
                            <div>
                              Select all {filteredRows.length} in dataset
                            </div>
                          ),
                          onClick: () => toggleAllRowsSelected(),
                        },
                      ]}
                    />
                  </div>
                </div>
              );
            },
            Cell: ({row}: {value: boolean; row: Row<DatasetStudy>}) => {
              const {indeterminate, ...rest} = row.getToggleRowSelectedProps();

              return (
                <>
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    data-cy="selectStudy"
                    ref={el =>
                      el && (el.indeterminate = indeterminate ?? false)
                    }
                    {...rest}
                  />
                </>
              );
            },
            disableSortBy: true,
            disableFilters: true,
          },
          {
            id: 'approval',
            Header: 'Order Cart',
            disableResizing: true,
            getResizerProps: () => {},
            sortType: (rowA, rowB) => {
              // this function runs every time a study is added/excluded/set to undetermined and sorts the rows based on the value in their approved field
              return rowA.original.approved > rowB.original.approved ? -1 : 1;
            },
            accessor: row => {
              return row.approved === 1
                ? 'Added'
                : row.approved === -1
                ? 'Excluded'
                : 'Undetermined';
            },
            width: 170,
            Cell: ({value}: {value: string | null}) => {
              if (value === null) {
                return null;
              }

              return (
                <Badge
                  type={
                    value === 'Added'
                      ? 'success'
                      : value === 'Excluded'
                      ? 'danger'
                      : 'info'
                  }
                >
                  {value}
                </Badge>
              );
            },
          },
          {
            Header: 'Study ID',
            id: 'studyId',
            width: 120,
            accessor: study => study.studyId.slice(-8),
            Cell: ({value, row}: {value?: string; row: Row<DatasetStudy>}) =>
              !_.isNil(value) ? (
                <a
                  href={`/datasets/${datasetId}/report/${row.original.studyId}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={e => e.preventDefault()}
                  className="text-primary hover:text-primary-active"
                >
                  {truncateStudyID(value)}
                </a>
              ) : null,
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'View Dicoms',
            id: 'viewDicom',
            width: 130,
            accessor: study =>
              !getDicomSeries(study.studyId, datasetDicomInfo)
                ? 'DICOMs are not available to view for this Study.'
                : 'View DICOMs',
            Cell: ({value, row}: {value?: string; row: Row<DatasetStudy>}) => {
              const dicomsCanBeViewed = getDicomSeries(
                row.original.studyId,
                datasetDicomInfo
              );
              return !_.isNil(value) ? (
                <Link
                  className="btn btn-xs btn-primary -my-1 w-24"
                  to={
                    dicomsCanBeViewed
                      ? `${location.pathname}/report/${row.original.studyId}/dicomViewer`
                      : '#'
                  }
                  target="_blank"
                >
                  <button
                    className="btn btn-xs btn-primary -my-2"
                    disabled={!dicomsCanBeViewed}
                  >
                    View DICOM
                  </button>
                </Link>
              ) : null;
            },
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
      {
        Header: 'Patient',
        columns: [
          {
            Header: 'Patient ID',
            accessor: 'patientId',
            Cell: ({value}: {value?: string}) => value?.slice(-10) ?? null,
            Filter: TextFilter,
            filter: 'inclues',
            width: 250,
          },
          {
            Header: 'Age',
            id: 'metadata.patientAgeSortable',
            accessor: row => {
              return row.metadata?.patientAgeSortable.as('days');
            },
            Cell: ({row}: {value: Duration; row: Row<DatasetStudy>}) => {
              let res = row.original.metadata?.patientAge ?? '';
              if (res[res.length - 1]?.toUpperCase() === 'Y') {
                res = res.slice(0, res.length - 1);
              }
              return res;
            },
            Filter: MinMaxFilter,
            filter: (rows, _columnIds, filterValue = []) => {
              if (!_.isNil(filterValue[0])) {
                rows = _.filter(
                  rows,
                  row =>
                    !_.isNil(row.original.metadata) &&
                    row.original.metadata?.patientAgeSortable.as('months') >=
                      Duration.fromObject({years: filterValue[0]}).as('months')
                );
              }
              if (!_.isNil(filterValue[1])) {
                rows = _.filter(
                  rows,
                  row =>
                    !_.isNil(row.original.metadata) &&
                    row.original.metadata?.patientAgeSortable.as('months') <=
                      Duration.fromObject({years: filterValue[1]}).as('months')
                );
              }
              return rows;
            },
          },

          {
            Header: 'Sex',
            accessor: 'metadata.patientSex',
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
      {
        Header: 'Study',
        columns: [
          {
            Header: 'Modality',
            id: 'modality',
            accessor: 'modality',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Body Part',
            id: 'bodyPart',
            accessor: 'bodyPart',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Exam Date',
            id: 'examDate',
            accessor: 'examDate',
            Cell: ({value}) => value?.toFormat('LLL dd, yyyy') ?? '',
            Filter: TextFilter,
            filter: 'inclues',
            sortType: (rowA, rowB) => {
              const a = rowA.original.examDate?.toSeconds() ?? 0;
              const b = rowB.original.examDate?.toSeconds() ?? 0;
              const diff = a - b;

              if (diff > 0) {
                return 1;
              }
              if (diff < 0) {
                return -1;
              }

              return 0;
            },
          },
          {
            Header: 'Source Location',
            id: 'sourceLocation',
            accessor: 'sourceLocation',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Priority Tags',
            id: 'priorityTags',
            accessor: row => row.tags,
            width: 250,
            Cell: ({
              value,
              row,
            }: {
              value: string | null;
              row: Row<DatasetStudy>;
            }) => {
              if (value === null || !row.original.tags) {
                return null;
              }

              return (
                <div className="flex text-xs gap-x-1 font-normal">
                  {row.original.tags.map((tag, i) => (
                    <Tag key={i} tag={tag} />
                  ))}
                </div>
              );
            },
            disableSortBy: true,
            Filter: TextFilter,
            filter: (rows, _columnIds, filterValue) =>
              _.filter(
                rows,
                row =>
                  _.isNil(filterValue) ||
                  _.some(row.original.tags, tags =>
                    tags.name.toLowerCase().includes(filterValue.toLowerCase())
                  )
              ),
          },
          {
            Header: () => {
              return (
                <span
                  title={
                    'Already ordered studies do not count towards your dataset pricing'
                  }
                >
                  Previously Ordered
                </span>
              );
            },
            id: 'previouslyOrdered',
            accessor: 'previouslyOrdered',
            Cell: ({value}) => {
              return (
                <span
                  title={
                    'Already ordered studies do not count towards your dataset pricing'
                  }
                >
                  {value?.valueOf() ? 'Yes' : 'No'}
                </span>
              );
            },
            Filter: TextFilter,
            filter: 'inclues',
            tooltip: 'Previously Ordered',
          },
        ],
      },
      {
        Header: 'Machine',
        columns: [
          {
            Header: 'Manufacturer',
            accessor: 'manufacturer',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Model',
            accessor: 'metadata.model',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Site',
            accessor: 'metadata.site',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
        ],
      },
      {
        // hidden column used to perform AND operation between series filters
        id: 'hiddenSeriesFilter',
        filter: (
          rows,
          _columnIds,
          filterValue: {
            seriesDescription?: string;
            sliceThickness?: number[];
            convolutionKernel?: string;
          } = {}
        ) =>
          _.filter(rows, row => {
            return _.some(row.original.metadata?.series, series => {
              // filter seriesDescription
              if (
                !_.isEmpty(filterValue.seriesDescription) &&
                !_.includes(
                  series.seriesDescription.toLowerCase(),
                  filterValue.seriesDescription?.toLowerCase()
                )
              ) {
                return false;
              }

              // filter convonlutionKernel
              if (
                !_.isEmpty(filterValue.convolutionKernel) &&
                !_.includes(
                  series.convolutionKernel.toLowerCase(),
                  filterValue.convolutionKernel?.toLowerCase()
                )
              ) {
                return false;
              }

              // filter sliceThickness
              if (!_.isNil(filterValue.sliceThickness)) {
                if (!_.isNil(filterValue.sliceThickness[0])) {
                  if (series.sliceThickness < filterValue.sliceThickness![0]) {
                    return false;
                  }
                }
                if (!_.isNil(filterValue.sliceThickness[1])) {
                  if (series.sliceThickness > filterValue.sliceThickness![1]) {
                    return false;
                  }
                }
              }

              return true;
            });
          }),
      },
      {
        Header: 'Acquisition',
        columns: [
          {
            id: 'seriesDescription',
            Header: 'Series',
            width: 200,
            accessor: row =>
              _.map(
                row.metadata?.series,
                series => series.seriesDescription
              ).join(', '),
            Cell: ({value}: {value: string; row: Row<DatasetStudy>}) => (
              <span title={value}>{value}</span>
            ),
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            id: 'sliceThickness',
            Header: 'Slice Thickness',
            width: 200,
            accessor: row =>
              _.map(row.metadata?.series, series => series.sliceThickness).join(
                ', '
              ),
            Cell: ({value}: {value: string; row: Row<DatasetStudy>}) => (
              <span title={value}>{value}</span>
            ),
            Filter: MinMaxFilter,
            filter: (rows, _columnIds, filterValue = []) =>
              _.filter(rows, row => {
                if (!_.isNil(filterValue[0])) {
                  if (
                    !_.some(
                      row.original.metadata?.series,
                      series => series.sliceThickness >= filterValue[0]
                    )
                  ) {
                    return false;
                  }
                }
                if (!_.isNil(filterValue[1])) {
                  if (
                    !_.some(
                      row.original.metadata?.series,
                      series => series.sliceThickness <= filterValue[1]
                    )
                  ) {
                    return false;
                  }
                }
                return true;
              }),
            sortType: (rowA, rowB, _id, desc) => {
              let a, b: number;
              if (!desc) {
                a =
                  _.minBy(rowA.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? Number.MAX_SAFE_INTEGER;
                b =
                  _.minBy(rowB.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? Number.MAX_SAFE_INTEGER;
              } else {
                a =
                  _.maxBy(rowA.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? -Number.MAX_SAFE_INTEGER;
                b =
                  _.maxBy(rowB.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? -Number.MAX_SAFE_INTEGER;
              }

              if (a > b) {
                return 1;
              }
              if (b > a) {
                return -1;
              }

              return 0;
            },
          },
          {
            id: 'convolutionKernel',
            Header: 'Kernel',
            width: 200,
            accessor: row =>
              _.map(
                row.metadata?.series,
                series => series.convolutionKernel
              ).join(', '),
            Cell: ({value}: {value: string; row: Row<DatasetStudy>}) => (
              <span title={value}>{value}</span>
            ),
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
      ..._.chain(visibleDicomHeaders)
        .map(header => _.find(dicomHeaders, {header}))
        .compact()
        .groupBy(header => header!.level)
        .toPairs()
        .map(([level, headers]) => ({
          Header: `${level} DICOM headers`,
          columns: headers.map(dicomHeader => {
            let accessor: string | ((row: DatasetStudy) => string) =
              'metadata.dicom_headers_study.' + dicomHeader.header;
            switch (dicomHeader?.level) {
              case 'Study':
                accessor = 'metadata.dicom_headers_study.' + dicomHeader.header;
                break;
              case 'Series':
                accessor = row =>
                  _.chain(row.metadata?.dicom_headers_series)
                    .values()
                    .map(seriesMetadata => seriesMetadata[dicomHeader.header])
                    .compact()
                    .join(', ')
                    .value();
                break;
              case 'Image':
                accessor = row =>
                  _.chain(row.metadata?.dicom_headers_images)
                    .values()
                    .map(seriesMetadata => seriesMetadata[dicomHeader.header])
                    .compact()
                    .map(val =>
                      _.isArray(val)
                        ? `[${val.join(', ')}]`
                        : `[${val.min} - ${val.max}]`
                    )
                    .join(', ')
                    .value();
                break;
            }

            return {
              Header: dicomHeader.description,
              id: dicomHeader.level + '_' + dicomHeader.header,
              accessor: accessor,
              Filter: TextFilter,
              filter: 'inclues',
            };
          }),
        }))
        .value(),
    ],
    [datasetId, dicomHeaders, visibleDicomHeaders, datasetDicomInfo]
  );

  const data = useMemo(
    () =>
      studies.map(study => {
        return {
          ...study,
          tags: getMatchedTags(
            study.studyId,
            studyTags,
            datasets,
            userTags
          ).filter(tag => tag.favorite && tag.tid !== datasetId),
        };
      }),
    [studies, studyTags, datasets, datasetId, userTags]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    page,
    pageCount,
    state: {pageIndex, pageSize, selectedRowIds, filters, hiddenColumns},
    gotoPage,
    toggleAllRowsSelected,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: {
        sortBy: [{id: 'approval', desc: false}],
        hiddenColumns: [
          ...(hideSelect ? ['selected'] : []),
          ...(hideOrderApproval ? ['approval'] : []),
          'hiddenSeriesFilter',
        ],
        pageSize: 100,
      },
      getRowId: row => row.studyId,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetGroupBy: false,
      autoResetPage: false,
      autoResetRowState: false,
      autoResetSelectedRows: false,
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useSticky,
    useResizeColumns
  );

  useEffect(() => {
    // Update series combination filter
    let seriesFilter:
      | undefined
      | {
          seriesDescription: string;
          convolutionKernel: string;
          sliceThickness: number[];
        } = {
      seriesDescription: _.find(filters, {id: 'seriesDescription'})?.value,
      convolutionKernel: _.find(filters, {id: 'convolutionKernel'})?.value,
      sliceThickness: _.find(filters, {id: 'sliceThickness'})?.value,
    };

    // Don't apply aggregate filter if all filters are empty
    if (!_.some(_.values(seriesFilter), v => v !== undefined)) {
      seriesFilter = undefined;
    }

    if (
      !_.isEqual(
        seriesFilter,
        _.find(filters, {id: 'hiddenSeriesFilter'})?.value
      )
    ) {
      setFilter('hiddenSeriesFilter', seriesFilter);
    }
  }, [filters, setFilter, hiddenColumns]);

  useEffect(() => {
    if (
      _.isNil(reportSelectionState.id) ||
      reportSelectionState.id !== `datasets/${datasetId}`
    ) {
      toggleAllRowsSelected(false);
      reportSelectionDispatch({
        type: ReportSelectionEventType.RESET,
        payload: {id: `datasets/${datasetId}`},
      });
    }
  }, [
    datasetId,
    reportSelectionDispatch,
    reportSelectionState.id,
    toggleAllRowsSelected,
  ]);

  useMountedLayoutEffect(() => {
    if (reportSelectionState.id === `datasets/${datasetId}`) {
      // the diff between selectedRowIds.keys and reportSelectionState.add.ids is what was just marked selected
      // this diff could be either the row the user clicked on or a row auto-selected inside the inner loop below
      const newId = _.find(
        Object.keys(selectedRowIds),
        id => !reportSelectionState.add.ids.has(id)
      );

      // newIndex will reset every time that the inner loop runs but lastClickedIndex will only reset when the user clicks an item
      const newIndex = page.findIndex(row => row.id === newId);
      setLastClickedIndex(newIndex);
      // this block handles selecting any rows in between the row being clicked on and the row that was clicked last
      if (shiftKeyDown && lastClickedIndex > -1) {
        // these bounds determine if we should be looping upward or downward
        const upperBound =
          lastClickedIndex > newIndex ? lastClickedIndex : newIndex;
        const lowerBound =
          lastClickedIndex < newIndex ? lastClickedIndex : newIndex;
        for (let i = lowerBound; i < upperBound; i++) {
          // eslint-disable-next-line security/detect-object-injection
          const row = page[i];
          if (row.isSelected) {
            for (let j = i + 1; j < upperBound; j++) {
              // eslint-disable-next-line security/detect-object-injection
              const laterRow = page[j];
              if (!laterRow.isSelected) {
                laterRow.toggleRowSelected(true);
              }
            }
          }
        }
      }
      reportSelectionDispatch({
        type: ReportSelectionEventType.RESET,
        payload: {
          id: `datasets/${datasetId}`,
          add: {
            ids: new Set(Object.keys(selectedRowIds)),
          },
        },
      });
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (pageIndex > pageCount) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount, gotoPage]);

  const renderHeader = (column: HeaderGroup<DatasetStudy>) => {
    return (
      <>
        {column.render('Header')}
        {column.canSort && (
          <span className="inline-block">
            {column.isSorted &&
              (column.isSortedDesc ? (
                <>
                  {' '}
                  <HiOutlineChevronDown />
                </>
              ) : (
                <>
                  {' '}
                  <HiOutlineChevronUp />
                </>
              ))}
          </span>
        )}
      </>
    );
  };

  return (
    <div className={cx(className)}>
      <div className={cx('bg-white', {splitview: splitView})}>
        <div
          className={cx({
            'splitview-panel-l': splitView,
          })}
        >
          <div className="rounded-lg shadow border border-gray-200">
            <table
              {...getTableProps({
                className: 'min-w-full divide-y divide-gray-200 sticky-table',
              })}
            >
              <thead className="bg-gray-50 divide-y divide-gray-200 header">
                {headerGroups.map((headerGroup, index) => {
                  const {key, ...headerGroupProps} =
                    headerGroup.getHeaderGroupProps();
                  return (
                    <tr
                      data-tour={`metadata-table-header-${index}`}
                      key={key}
                      {...headerGroupProps}
                    >
                      {headerGroup.headers.map(column => {
                        const {key, style, ...headerProps} =
                          column.getHeaderProps({
                            ...column.getResizerProps(),
                            className:
                              'bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
                          });
                        return (
                          <th
                            key={key}
                            style={{
                              ...style,
                              zIndex: ['table_0', ...stickyColumnIDs].includes(
                                column.id
                              )
                                ? 1
                                : style?.zIndex,
                              position: [
                                'table_0',
                                ...stickyColumnIDs,
                              ].includes(column.id)
                                ? 'sticky'
                                : 'relative',
                            }}
                            {...headerProps}
                          >
                            <div className="inline-block relative">
                              {!(column.canSort || column.canFilter) ? (
                                renderHeader(column)
                              ) : (
                                <ColumnDropdown
                                  label={renderHeader(column)}
                                  disabled={(column.columns?.length ?? 0) > 0}
                                >
                                  <div className="text-sm font-normal space-y-2">
                                    {column.canSort && (
                                      <div className="space-y-2">
                                        <button
                                          className={cx(
                                            'text-gray-700 hover:text-gray-800 block',
                                            {
                                              'font-medium text-gray-800':
                                                column.isSorted &&
                                                !column.isSortedDesc,
                                            }
                                          )}
                                          disabled={
                                            column.isSorted &&
                                            !column.isSortedDesc
                                          }
                                          onClick={() =>
                                            column.toggleSortBy(false)
                                          }
                                        >
                                          <HiSortAscending className="text-gray-400 mr-2 inline-block w-5 h-5" />
                                          Sort Ascending (A-Z)
                                        </button>
                                        <button
                                          className={cx(
                                            'text-gray-700 hover:text-gray-800 block',
                                            {
                                              'font-medium text-gray-800':
                                                column.isSorted &&
                                                column.isSortedDesc,
                                            }
                                          )}
                                          disabled={
                                            column.isSorted &&
                                            column.isSortedDesc
                                          }
                                          onClick={() =>
                                            column.toggleSortBy(true)
                                          }
                                        >
                                          <HiSortDescending className="text-gray-400 mr-2 inline-block w-5 h-5" />
                                          Sort Descending (Z-A)
                                        </button>
                                      </div>
                                    )}

                                    {column.filter && column.render('Filter')}
                                  </div>
                                </ColumnDropdown>
                              )}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody
                {...getTableBodyProps({
                  className: 'bg-white divide-y divide-gray-200',
                })}
              >
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  const {key: rowKey, ...rowProps} = row.getRowProps({
                    className: cx('group'),
                  });
                  return (
                    <Fragment key={rowKey}>
                      <tr {...rowProps}>
                        {row.cells.map((cell, cellIndex) => {
                          const openStudyOnClick =
                            splitView &&
                            ![
                              'expand',
                              'index',
                              'selected',
                              'approval',
                            ].includes(cell.column.id);

                          const {key, ...cellProps} = cell.getCellProps({
                            className: cx(
                              'px-6 py-4 whitespace-nowrap text-sm z-auto',
                              row.original.studyId === openedStudyID
                                ? 'bg-gray-200'
                                : cellIndex < 2
                                ? 'bg-gray-50 text-gray-500'
                                : 'bg-white',
                              {
                                truncate: ![
                                  'index',
                                  'expand',
                                  'selected',
                                  'approval',
                                  'priorityTags',
                                ].includes(cell.column.id),
                                'overflow-x-auto': ['priorityTags'].includes(
                                  cell.column.id
                                ),
                                'cursor-pointer group-hover:text-gray-700':
                                  openStudyOnClick,
                                'group-hover:bg-gray-100': splitView,
                              }
                            ),
                          });

                          return (
                            <td
                              key={key}
                              {...cellProps}
                              title={
                                _.isString(cell.value) ? cell.value : undefined
                              }
                              onClick={() =>
                                openStudyOnClick &&
                                openedStudyIDChange(row.original.studyId)
                              }
                            >
                              {cell.column.id === 'index'
                                ? rowIndex + 1 + pageSize * pageIndex
                                : cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded &&
                        _.map(
                          row.original.metadata!.dicom_headers_series,
                          (seriesHeaders, seriesID) => {
                            const imageHeaders = _.get(
                              row.original.metadata!.dicom_headers_images,
                              seriesID
                            );

                            return (
                              <tr key={`${rowKey}-${seriesID}`} {...rowProps}>
                                {row.cells.map((cell, cellIndex) => {
                                  const simpleSeries = _.find(
                                    row.original.metadata!.series,
                                    {
                                      seriesID: seriesID,
                                    }
                                  );

                                  const colSplit = cell.column.id.split('_');
                                  const header = colSplit[colSplit.length - 1];

                                  const isSeries =
                                    colSplit[colSplit.length - 2] === 'Series';
                                  const isImage =
                                    colSplit[colSplit.length - 2] === 'Image';
                                  let val = '';
                                  if (isSeries) {
                                    val = _.get(seriesHeaders, header);
                                  } else if (isImage) {
                                    const imageVal = _.get(
                                      imageHeaders,
                                      header
                                    );

                                    if (imageVal !== undefined) {
                                      val = _.isArray(imageVal)
                                        ? `[${imageVal.join(', ')}]`
                                        : `[${imageVal.min} - ${imageVal.max}]`;
                                    }
                                  }

                                  const {key, ...cellProps} = cell.getCellProps(
                                    {
                                      className: cx(
                                        'px-6 py-4 whitespace-ellipsis text-sm z-auto truncate',
                                        cellIndex < 2
                                          ? 'bg-gray-50 text-gray-500'
                                          : 'bg-white'
                                      ),
                                    }
                                  );
                                  return (
                                    <td key={key} {...cellProps}>
                                      {cell.column.id === 'seriesDescription' ||
                                      cell.column.id === 'sliceThickness' ||
                                      cell.column.id === 'convolutionKernel'
                                        ? simpleSeries && (
                                            <span
                                              title={`${
                                                simpleSeries[cell.column.id]
                                              }`}
                                            >
                                              {simpleSeries[cell.column.id]}
                                            </span>
                                          )
                                        : val !== '' && (
                                            <span title={val}>{val}</span>
                                          )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          }
                        )}
                    </Fragment>
                  );
                })}
                {rows.length === 0 && (
                  <tr>
                    <td
                      colSpan={visibleColumns.length}
                      className="px-6 py-4 whiespace-nowrap text-sm text-center italic text-gray-400"
                    >
                      No studies found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {openedStudyID && (
          <div
            className={cx({
              'splitview-panel-r xl:border-l-2 xl:border-b-0 border-b-2 border-gray-400':
                splitView,
            })}
          >
            <div>
              <div className="sticky top-0 z-10 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex justify-between space-x-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  <Link
                    to={`/datasets/${datasetId}/report/${openedStudyID}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary hover:text-primary-active"
                  >
                    {truncateStudyID(openedStudyID)}
                  </Link>
                </h3>
                <button
                  type="button"
                  className={cx('text-gray-400 hover:text-gray-500')}
                  onClick={() => openedStudyIDChange(undefined)}
                >
                  <span className="sr-only">Close</span>

                  <HiOutlineX className="h-6 w-6" />
                </button>
              </div>
              <div className="p-6">
                {report && (
                  <ReportComponent
                    report={report}
                    PHIReported={() => {
                      queryClient.removeQueries(['report', report.studyId]);
                      queryClient.removeQueries(['dataset']);
                      queryClient.removeQueries(['dataset']);
                      queryClient.removeQueries(['search']);
                      navigate({
                        pathname: '/',
                      });
                    }}
                    dicomSeries={getDicomSeries(
                      report.studyId,
                      datasetDicomInfo
                    )}
                    splitViewDataset={true}
                  />
                )}
              </div>
              {!report && (
                <>
                  {reportLoading && <Loading />}
                  {reportError && <div>Error loading report</div>}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Pagination
        max={pageCount}
        current={pageIndex + 1}
        linkFunc={num => {
          gotoPage(num - 1);
        }}
      />
    </div>
  );
};
