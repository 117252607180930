/* eslint-disable security/detect-object-injection */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {useQuery} from 'react-query';
import ReactTooltip from 'react-tooltip';
import {HiInformationCircle} from 'react-icons/hi';
import {Toggle} from '../../core/components/toggle';
import {
  ExtendedSearchDriverOptions,
  fetchAllStudiesForListOfPatientIDs,
  parseElasticSearchBody,
  PatientSortOption,
} from '../../models/minerva';
import {Loading} from '../../core/components/loading';
import CustomFacet from './CustomFacet';
import {SearchResult} from '@elastic/search-ui';
import {PatientGroupsSortDropdown} from './PatientGroupsSortDropdown';

type PatientSearchProps = {
  config: ExtendedSearchDriverOptions;
  results: any;
  showAllStudiesPerPatient: boolean;
  lastRequestBody: any;
  showAllStudiesPerPatientChange: (value: boolean) => void;
  additionalPatientStudiesChange: (value: SearchResult[]) => void;
  toggleGroupPatientIDs: () => void;
  setSortPatientIDsBy: (value: PatientSortOption) => void;
};

export const PatientSearch: React.FC<PatientSearchProps> = ({
  config,
  results,
  showAllStudiesPerPatient,
  showAllStudiesPerPatientChange,
  lastRequestBody,
  additionalPatientStudiesChange,
  toggleGroupPatientIDs,
  setSortPatientIDsBy,
}) => {
  const resultFields = config.searchQuery?.result_fields;
  const fetchAdditionalStudies = async () => {
    const patient_ids_set: Set<string> = new Set(
      results.map((result: any) => {
        return result['patient_id']?.raw;
      })
    );
    const patient_ids = Array.from(patient_ids_set);
    return await fetchAllStudiesForListOfPatientIDs({
      patient_ids,
      connector: config.apiConnector,
      resultFields: resultFields!,
    });
  };

  const {isLoading: additionalPatientStudiesLoading} = useQuery(
    ['additionalPatientStudies', results, showAllStudiesPerPatient],
    async () => {
      return await fetchAdditionalStudies();
    },
    {
      enabled: config.searchQuery.groupPatientIDs && showAllStudiesPerPatient,
      keepPreviousData: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      onSuccess: response => {
        additionalPatientStudiesChange(response.results);
      },
    }
  );

  const [textFields, filters] = parseElasticSearchBody(lastRequestBody);

  const isGroupingDisabled =
    !lastRequestBody ||
    (textFields?.length === 0 && filters?.length === 0) ||
    config?.searchQuery?.useVectorSearch;

  return (
    <>
      <div className="flex justify-between">
        <p
          className={`${
            isGroupingDisabled ? 'text-gray-400' : 'text-gray-700'
          }`}
        >
          Group Patient IDs
        </p>
        <div
          title={
            isGroupingDisabled
              ? 'Add a search term or filter to enable patient grouping'
              : ''
          }
        >
          <Toggle
            checked={config.searchQuery.groupPatientIDs}
            onChange={toggleGroupPatientIDs}
            disabled={isGroupingDisabled}
          />
        </div>
      </div>
      {config.searchQuery.groupPatientIDs && (
        <div>
          <PatientGroupsSortDropdown
            config={config}
            onSortChange={option => setSortPatientIDsBy(option)}
          />
          <div className="flex justify-between my-3">
            <input
              type="checkbox"
              id="showAllStudiesPerPatient"
              className="checkbox-input"
              checked={showAllStudiesPerPatient}
              onChange={() => {
                showAllStudiesPerPatientChange(!showAllStudiesPerPatient);
              }}
              disabled={additionalPatientStudiesLoading}
            ></input>{' '}
            <label htmlFor="showAllStudiesPerPatient" className="text-sm">
              Show Additional Patient Studies{' '}
            </label>
            <HiInformationCircle
              className="mx-2 text-primary hover:text-primary-active hover:cursor-pointer"
              data-tip='Show more patient studies with the selected filters, but not the keywords. These are indicated with <span style="color: #F59E0B; font-size: 25px">•</span>'
              data-html={true}
            />
            <ReactTooltip />
          </div>
        </div>
      )}
      {additionalPatientStudiesLoading && <Loading />}
      <CustomFacet
        key={'patient_id.keyword'}
        field={'patient_id.keyword'}
        label={'Patient ID'}
        filterType="any"
        isFilterable
        changeOptions={(options: any[]) => {
          return options.map((option: any) => {
            const copyOption = {
              ...option,
            };
            copyOption.value = option.value.slice(15);
            return copyOption;
          });
        }}
      />
    </>
  );
};
