/* eslint-disable security/detect-object-injection */
import {useEffect, useState} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Helmet} from 'react-helmet';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {
  SavedSearches,
  fetchAllSavedSearches,
  unsaveSearch,
} from '../../models/saved-search';
import {ToastMessage} from '../../core/components/toast';
import {Modal} from '../../core/layout/modal';
import {Loading} from '../../core/components/loading';
import {SaveSearchCard} from '../../components/save-search/save-search-card';
import {ApplicationShell} from '../../core/layout/application-shell';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

type UnsaveSearchInput = {
  ssId: number;
};

export const SavedSearchesList = () => {
  const queryClient = useQueryClient();
  const api = useAxios();

  const [modalType, modalTypeChange] = useState<'edit' | 'delete'>();
  const [modalOpen, modalOpenChange] = useState(false);
  const [modalSavedSearch, modalSavedSearchChange] = useState<SavedSearches>();
  // invalidate saved searches queryKey using useEffect
  useEffect(() => {
    queryClient.invalidateQueries(['saved-searches']);
  }, [queryClient]);

  const {data: savedSearches, isLoading: savedSearchesLoading} = useQuery(
    ['saved-searches'],
    () => fetchAllSavedSearches(api),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const deleteSavedSearchMut = useMutation(
    (input: UnsaveSearchInput) => unsaveSearch(api, input.ssId),
    {
      onSuccess: (response, {ssId}: UnsaveSearchInput) => {
        // Success
        toast(<ToastMessage title="Saved search deleted" icon="success" />);

        // Update currently loaded dataset
        const newDatasets = _.filter(
          savedSearches,
          savedSearch => savedSearch.ssId !== ssId
        );
        queryClient.setQueryData(['saved-searches'], newDatasets);
        closeModal();
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error deleting saved search';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const renderSavedSearches = () => {
    return (
      <div className="min-w-full space-y-4">
        {_.map(savedSearches, savedSearch => {
          return (
            <>
              <SaveSearchCard
                savedSearch={savedSearch}
                action={actionType => {
                  if (actionType === 'delete') {
                    openModal(actionType, savedSearch);
                  }
                }}
              />
            </>
          );
        })}
      </div>
    );
  };

  const openModal = (
    type: 'edit' | 'delete',
    savedSearches?: SavedSearches
  ) => {
    // @todo: Don't use empty savedSearches for create modal
    const emptySavedSearches: SavedSearches = {
      ssId: 0,
      userId: 0,
      url: '',
      numberOfResultsSaved: 0,
      currentNumberOfResults: 0,
      elasticSearchBody: {},
      createdAt: '',
    };

    modalTypeChange(type);
    modalSavedSearchChange(_.cloneDeep(savedSearches || emptySavedSearches));
    modalOpenChange(true);
  };

  const closeModal = () => {
    modalOpenChange(false);
    modalTypeChange(undefined);
    modalSavedSearchChange(undefined);
  };

  const renderDeleteModal = (savedSearch: SavedSearches) => {
    return (
      <div>
        <div className="text-lg mb-3">Delete Saved Search</div>
        <div className="text-sm text-gray-500 mb-3">
          This can not be undone, do you want to continue?
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btn btn-danger"
            data-cy="confirmDeleteSavedSearchBtn"
            onClick={() => {
              deleteSavedSearchMut.mutate({
                ssId: savedSearch!.ssId,
              });
            }}
            disabled={deleteSavedSearchMut.isLoading}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Saved Searches</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        <div className="mb-5 flex justify-between">
          <h1 className="text-3xl">Your Saved Searches</h1>
        </div>

        <div>
          {savedSearchesLoading ? (
            <Loading text="Loading saved searches..." />
          ) : savedSearches?.length ? (
            renderSavedSearches()
          ) : (
            <div className="flex flex-col">
              <div className="justify-self-center text-base leading-7 font-normal text-gray-500 pb-4">
                You don&apos;t have any saved search yet. Start a{' '}
                <Link className="link" to="/">
                  search
                </Link>{' '}
                to save one.
              </div>
            </div>
          )}
        </div>
      </ApplicationShell>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        className="w-96 max-w-lg"
        hideExitButton={_.includes(['requestConfirmed'], modalType)}
      >
        {modalType === 'delete' && renderDeleteModal(modalSavedSearch!)}
      </Modal>
    </>
  );
};
