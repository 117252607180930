import React from 'react';
import {FaHeart, FaRegHeart} from 'react-icons/fa';
import {toast} from 'react-toastify';
import cx from 'classnames';

import {ToastMessage} from '../../core/components/toast';
import {
  saveSearch,
  unsaveSearch,
  checkSearchSaved,
} from '../../models/saved-search';
import {useQuery, useQueryClient} from 'react-query';
import {useAxios} from 'src/utils/http';

export const SaveSearchButton = ({
  userId: user,
  studyCount,
  lastRequestBody,
  displayMode = 'both',
  onReload,
}: {
  userId: number;
  studyCount: number;
  lastRequestBody: any;
  displayMode?: 'both' | 'icon' | 'text';
  onReload?: () => void;
}) => {
  const currentURL = window.location.href;
  const queryClient = useQueryClient();
  const http = useAxios();

  const {data: savedSearchId, isLoading: savedSearchIdLoading} = useQuery(
    ['searchIsSaved', user, JSON.stringify(lastRequestBody)],
    () =>
      checkSearchSaved(http, user, lastRequestBody).then(data => {
        return data?.ss_id;
      }),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
  const searchIsSaved = savedSearchId !== undefined;

  const handleSaveSearch = () => {
    if (searchIsSaved) {
      unsaveSearch(http, savedSearchId)
        .then(() => {
          queryClient.invalidateQueries(['searchIsSaved', user]);
          toast(<ToastMessage title="Search unsaved" icon="success" />);
        })
        .catch(error => {
          console.error('Error unsaving search:', error);
          toast(<ToastMessage title="Error unsaving search" icon="error" />);
        });
    } else {
      saveSearch(http, user, currentURL, studyCount, lastRequestBody)
        .then(() => {
          queryClient.invalidateQueries(['searchIsSaved', user]);
          toast(<ToastMessage title="Search saved!" icon="success" />);
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            toast(
              <ToastMessage
                title={'Bad Request: ' + error.response.data.message}
                icon="error"
              />
            );
          } else {
            console.error('Error saving search:', error);
            toast(<ToastMessage title="Error saving search" icon="error" />);
          }
        });
    }
    if (onReload) {
      onReload();
    }
  };

  return (
    <button
      className={cx(
        'relative inline-flex items-center shadow-sm rounded-md btn',
        {
          'btn-primary': searchIsSaved,
          'btn-white btn-outline': !searchIsSaved,
          disabled: savedSearchIdLoading,
        }
      )}
      onClick={handleSaveSearch}
    >
      {displayMode !== 'text' &&
        (searchIsSaved ? (
          <FaHeart className="mr-2" />
        ) : (
          <FaRegHeart className="mr-2" />
        ))}
      {displayMode !== 'icon' &&
        ` ${searchIsSaved ? 'Search saved!' : 'Save search'}`}
    </button>
  );
};
