import {
  SearchDriverOptions,
  RequestState,
  APIConnector,
  FieldConfiguration,
  Filter,
  QueryConfig,
} from '@elastic/search-ui';
import {SearchRequest} from '@segmed/search-ui-elasticsearch-connector';
import _ from 'lodash';
import {
  BoolQuery,
  MultiMatchQuery,
  spanNearQuery,
  MatchPhraseQuery,
  spanTermQuery,
  WildcardQuery,
} from 'elastic-builder';

import {trackEvent} from '../utils/tracking';
import {SelectOption} from '../core/components/select';
import {removeTextInParentheses} from './snomed-concept';
export type MinervaSearchTermString = {
  type: 'string';
  term: string;
};

export type MinervaSearchQuery = {
  searchTerms: MinervaSearchTermString[];
};

export type MinervaSearchGroup = {};

export type ExtendedSearchDriverOptions = SearchDriverOptions & {
  searchQuery: {
    groupPatientIDs: boolean;
    queryBuilderQuery?: Object;
    sortPatientIDsBy: PatientSortOption;
    useVectorSearch: boolean;
  };
  appSearchEndpoint: string;
};

export type SortDirection = 'asc' | 'desc';

export type SortOption = {
  field: string;
  direction: SortDirection;
};

// ------------------------- search form interfaces -------------------------

export interface TextField {
  id: string;
  value: string;
  operation:
    | 'contains substring'
    | 'contains substring (no negations)'
    | 'excludes substring';
  reportType: 'Report' | 'Impression';
  snomedTerms?: string[];
}

export const newTextField = (value?: string): TextField => {
  return {
    id: _.uniqueId(),
    value: value ?? '',
    operation: 'contains substring',
    reportType: 'Report',
  };
};

export const textFieldReportOptions: SelectOption<'Report' | 'Impression'>[] = [
  {value: 'Report', label: 'Report'},
  {value: 'Impression', label: 'Impression'},
];

export const textFieldOperationOptions: SelectOption<
  | 'contains substring'
  | 'contains substring (no negations)'
  | 'excludes substring'
>[] = [
  {value: 'contains substring', label: 'Contains'},
  {value: 'excludes substring', label: 'Excludes'},
  {
    value: 'contains substring (no negations)',
    label: 'Contains (No Negations)',
  },
];

export const REPORT_NO_NEGATIONS = 'report_no_negations';

// ------------------------- fetches just the study_ids matching search query for custom select dropdown -------------------------
export const fetchMatchingStudyIds = ({
  config,
  requestState,
  queryConfig,
  numStudies = 5000,
}: {
  config: ExtendedSearchDriverOptions;
  requestState: RequestState;
  queryConfig: Object;
  numStudies?: number;
}) => {
  // need to take the current page and multiply it by normal resultsPerPage in order to find the first result index
  const current = requestState.current || 1;
  const resultsPerPage = requestState.resultsPerPage || 50;

  // Calculate the index of the first result on the current page
  const firstResultIndex = (current - 1) * resultsPerPage;
  const lastIndex = firstResultIndex + numStudies + 1;

  const req: RequestState = {
    ...requestState,
    current: 1,
    resultsPerPage: lastIndex,
  };

  // we trim the query because we only need the study_ids. Filters and search terms still work properly, but we don't need to calculate anything else
  const qc: any = {
    ...queryConfig,
    result_fields: {
      study_id: {
        raw: {},
        snippet: {size: 200, fallback: true},
      },
    },
    groupPatientIDs: config.searchQuery.groupPatientIDs,
    highlight: {},
  };

  return config.apiConnector.onSearch(req, qc).then(response => {
    let studyIDsBeforeCurrentPage = [];
    const studyIDsAfterCurrentPage = response.results
      .map(result => result.study_id.raw)
      .slice(firstResultIndex, firstResultIndex + numStudies);
    // if the number of studies is greater than the number of studies after the current page, we need to get the studies before the current page
    if (numStudies > studyIDsAfterCurrentPage.length) {
      const numNeeded = numStudies - studyIDsAfterCurrentPage.length;
      studyIDsBeforeCurrentPage = response.results
        .map(result => result.study_id.raw)
        .slice(firstResultIndex - numNeeded, firstResultIndex);
    }
    return [...studyIDsBeforeCurrentPage, ...studyIDsAfterCurrentPage];
  });
};

// ------------------------- functions for de-constructing elasticsearch query -------------------------
function isExcludes(searchBox: any) {
  return hasKey(searchBox, 'must_not');
}

function isExactMatch(searchBox: any) {
  return hasKey(searchBox, 'match_phrase');
}

function isImpression(searchBox: any) {
  return hasKey(searchBox, 'span_near');
}

function impressionHasMultipleWords(searchBox: any) {
  return (
    isImpression(searchBox) &&
    (Array.isArray(searchBox.bool.must) ||
      Array.isArray(searchBox.bool.must_not))
  );
}

const hasKey = (node: any, key: string): boolean => {
  if (Array.isArray(node)) {
    return node.some(item => hasKey(item, key));
  } else if (typeof node === 'object' && node !== null) {
    // eslint-disable-next-line security/detect-object-injection
    if (node[key]) {
      return true;
    } else {
      return Object.values(node).some(value => hasKey(value, key));
    }
  }
  return false;
};

function extractFilters(jsonObj: any): string {
  try {
    const filters: string[] = [];
    if (
      jsonObj.post_filter &&
      jsonObj.post_filter.bool &&
      jsonObj.post_filter.bool.must
    ) {
      for (const mustClause of jsonObj.post_filter.bool.must) {
        if (mustClause.bool && mustClause.bool.should) {
          let filterName = '';
          const filterValues = [];
          for (const shouldClause of mustClause.bool.should) {
            const filter = shouldClause.term;
            filterName = String(Object.keys(filter)[0]).replace('.keyword', '');
            // eslint-disable-next-line
            filterValues.push(filter[Object.keys(filter)[0]]);
          }
          filters.push(`${filterName}: ${filterValues.join(', ')}`);
        }
      }
    }
    // age filter with query
    // "query": {"bool": {"must": [{"bool": {"filter": [{"bool": {"filter": [{"range": {"age_num": {"gte": 20, "lte": 50}}}]}}]}},
    if (
      jsonObj.query &&
      jsonObj.query.bool &&
      Array.isArray(jsonObj.query.bool.must) &&
      jsonObj.query.bool.must[0].bool &&
      jsonObj.query.bool.must[0].bool.filter &&
      jsonObj.query.bool.must[0].bool.filter[0].bool &&
      jsonObj.query.bool.must[0].bool.filter[0].bool.filter
    ) {
      for (const mustClause of jsonObj.query.bool.must[0].bool.filter[0].bool
        .filter) {
        if (mustClause.range && mustClause.range.age_num) {
          const ageRange = mustClause.range.age_num;
          const gte = ageRange.gte !== undefined ? ageRange.gte : 'any';
          const lte = ageRange.lte !== undefined ? ageRange.lte : 'any';
          filters.push(`Age: ${gte} to ${lte}`);
        }
      }
    }
    // age filter without query
    // "query": {"bool": {"filter": [{"bool": {"filter": [{"range": {"age_num": {"gte": 22, "lte": 24}}}]}}]}},
    if (
      jsonObj.query &&
      jsonObj.query.bool &&
      jsonObj.query.bool.filter &&
      jsonObj.query.bool.filter[0].bool &&
      jsonObj.query.bool.filter[0].bool.filter
    ) {
      for (const mustClause of jsonObj.query.bool.filter[0].bool.filter) {
        if (mustClause.range && mustClause.range.age_num) {
          const ageRange = mustClause.range.age_num;
          const gte = ageRange.gte !== undefined ? ageRange.gte : 'any';
          const lte = ageRange.lte !== undefined ? ageRange.lte : 'any';
          filters.push(`Age: ${gte} to ${lte}`);
        }
      }
    }

    return filters.join('; ');
  } catch (error) {
    console.error('Error parsing Elasticsearch query', error);
    return '';
  }
}

/* eslint-disable security/detect-object-injection */
function extractTextFieldFromSearchBox(
  searchBox: any,
  uniqueID: string
): TextField {
  let operation:
    | 'contains substring'
    | 'contains substring (no negations)'
    | 'excludes substring' = 'contains substring';
  let reportType: 'Report' | 'Impression' = 'Report';
  if (isExcludes(searchBox)) {
    operation = 'excludes substring';
    let value;

    if (!isImpression(searchBox) && !isExactMatch(searchBox)) {
      if (!Array.isArray(searchBox.bool.must_not)) {
        // EXCLUDES
        value = searchBox.bool.must_not.multi_match.query;
      } else {
        // EXCLUDES with ORs
        value = searchBox.bool.must_not
          .filter((item: any) => item.multi_match && item.multi_match.query)
          .map((item: any) => item.multi_match.query)
          .join(' | ');
      }
    } else if (isImpression(searchBox) && !isExactMatch(searchBox)) {
      reportType = 'Impression';

      if (!impressionHasMultipleWords(searchBox)) {
        // IMPRESSIONS EXCLUDES
        value = searchBox.bool.must_not.span_near.clauses[1].span_term.report;
      } else {
        // IMPRESSIONS EXCLUDES with multiple words
        value = searchBox.bool.must_not
          .filter(
            (term: any) =>
              term.span_near &&
              term.span_near.clauses &&
              term.span_near.clauses.length === 2
          )
          .map((term: any) => term.span_near.clauses[1].span_term.report)
          .join(' ');
      }
    } else if (isExactMatch(searchBox)) {
      if (!Array.isArray(searchBox.bool.should)) {
        // EXACT MATCH EXCLUDES
        value = `"${searchBox.bool.should.bool.must_not.match_phrase.report.query}"`;
      } else {
        // EXACT MATCH EXCLUDES with ORs
        value = searchBox.bool.should
          .map(
            (clause: any) =>
              `"${clause.bool.must_not.match_phrase.report.query}"`
          )
          .join(' | ');
      }
    }

    return {
      id: uniqueID,
      value: value,
      operation: operation,
      reportType: reportType,
    };
  } else if (isImpression(searchBox) && !isExactMatch(searchBox)) {
    operation = 'contains substring';
    reportType = 'Impression';
    let isNoNegations = false;
    let value;

    if (!impressionHasMultipleWords(searchBox)) {
      if (!Array.isArray(searchBox.bool.should)) {
        // IMPRESSIONS
        const spanTermKey = Object.keys(
          searchBox.bool.should.span_near.clauses[1].span_term
        )[0];
        const field =
          spanTermKey === 'report_no_negations'
            ? 'report_no_negations'
            : 'report';
        if (field === 'report_no_negations') {
          isNoNegations = true;
        }
        value = searchBox.bool.should.span_near.clauses[1].span_term[field];
      } else {
        // IMPRESSIONS with ORs
        value = searchBox.bool.should
          .map((impressionTerm: any) => {
            if (
              impressionTerm.span_near &&
              impressionTerm.span_near.clauses &&
              impressionTerm.span_near.clauses.length > 1
            ) {
              const firstClause = impressionTerm.span_near.clauses[0];
              const secondClause = impressionTerm.span_near.clauses[1];
              const field = Object.keys(firstClause.span_term)[0];

              if (field === 'report_no_negations') {
                isNoNegations = true;
              }

              if (firstClause.span_term[field] === 'impression') {
                return secondClause.span_term[field];
              }
            }
            return '';
          })
          .filter(Boolean)
          .join(' | ');
      }
    } else {
      // IMPRESSIONS with multiple words
      value = searchBox.bool.must
        .map((impressionTerm: any) => {
          if (
            impressionTerm.span_near &&
            impressionTerm.span_near.clauses &&
            impressionTerm.span_near.clauses.length === 2
          ) {
            const spanTermKey = Object.keys(
              impressionTerm.span_near.clauses[1].span_term
            )[0];
            const field =
              spanTermKey === 'report_no_negations'
                ? 'report_no_negations'
                : 'report';
            if (field === 'report_no_negations') {
              isNoNegations = true;
            }
            return impressionTerm.span_near.clauses[1].span_term[field];
          }
          return '';
        })
        .filter(Boolean)
        .join(' ');
    }

    if (isNoNegations) {
      operation = 'contains substring (no negations)';
    }

    return {
      id: uniqueID,
      value: value,
      operation: operation,
      reportType: reportType,
    };
  } else if (isExactMatch(searchBox)) {
    let isNoNegations = false;
    let value;

    if (!Array.isArray(searchBox.bool.should)) {
      // EXACT MATCH
      const field = searchBox.bool.should.match_phrase.report
        ? 'report'
        : 'report_no_negations';
      if (field === 'report_no_negations') {
        isNoNegations = true;
      }
      value = `"${searchBox.bool.should.match_phrase[field].query}"`;
    } else {
      // EXACT MATCH with ORs usually used for synonyms
      value = searchBox.bool.should
        .map((clause: any) => {
          const field = clause.match_phrase.report
            ? 'report'
            : 'report_no_negations';
          if (field === 'report_no_negations') {
            isNoNegations = true;
          }
          return `"${clause.match_phrase[field].query}"`;
        })
        .join(' | ');
    }

    if (isNoNegations) {
      operation = 'contains substring (no negations)';
    }

    return {
      id: uniqueID,
      value: value,
      operation: operation,
      reportType: reportType,
    };
  } else if (Array.isArray(searchBox.bool.should)) {
    let orQueries = '';
    for (let i = 0; i < searchBox.bool.should.length; i++) {
      const orQuery = searchBox.bool.should[i];
      if (orQuery.multi_match && orQuery.multi_match.query) {
        if (orQuery.multi_match.fields[0] === 'report_no_negations') {
          operation = 'contains substring (no negations)';
        }
        orQueries += orQuery.multi_match.query;
        if (i < searchBox.bool.should.length - 1) {
          orQueries += ' | ';
        }
      }
    }
    return {
      id: uniqueID,
      value: orQueries,
      operation: operation,
      reportType: reportType,
    };
  } else {
    // base case
    if (searchBox.bool.should.multi_match.fields[0] === 'report_no_negations') {
      operation = 'contains substring (no negations)';
    }
    const searchboxQuery = searchBox.bool.should.multi_match.query;
    return {
      id: uniqueID,
      value: searchboxQuery,
      operation: operation,
      reportType: reportType,
    };
  }
}

export const parseElasticSearchBody = (
  elasticSearchBody: any
): [TextField[], string, boolean, string, boolean] => {
  const textFields: TextField[] = [];
  let filters = '';
  let groupPatientsByID = false;
  let sortPatientIDsBy = '';
  let useVectorSearch = false;

  const extractedFilters = extractFilters(elasticSearchBody);
  filters = extractedFilters;

  if (elasticSearchBody.groupPatientIDs) {
    groupPatientsByID = elasticSearchBody.groupPatientIDs;
    sortPatientIDsBy = elasticSearchBody.sortPatientIDsBy;
  }

  if (elasticSearchBody.useVectorSearch) {
    useVectorSearch = elasticSearchBody.useVectorSearch;
  }

  if (
    elasticSearchBody.query &&
    elasticSearchBody.query.bool &&
    elasticSearchBody.query.bool.must
  ) {
    const searchBoxes = elasticSearchBody.query.bool.must;
    if (Array.isArray(searchBoxes)) {
      for (let i = 0; i < searchBoxes.length; i++) {
        textFields.push(
          extractTextFieldFromSearchBox(searchBoxes[i], i.toString())
        );
      }
    } else {
      textFields.push(
        extractTextFieldFromSearchBox(elasticSearchBody.query.bool.must, '1')
      );
    }
  }

  return [
    textFields,
    filters,
    groupPatientsByID,
    sortPatientIDsBy,
    useVectorSearch,
  ];
};

export function removeAggsField(jsonObj: SearchRequest) {
  try {
    // Check and remove the 'aggs' field if it exists
    if (jsonObj.aggs) {
      delete jsonObj.aggs;
    }

    return jsonObj;
  } catch (error) {
    return jsonObj; // Return the original string in case of error
  }
}

export const extractSearchTerms = (
  elasticSearchBody: SearchRequest
): Set<string> => {
  elasticSearchBody = _.cloneDeep(elasticSearchBody);

  const uniqueQueries = new Set<string>();

  const elasticSearchBodyObj = removeAggsField(elasticSearchBody) as any;

  // recursively get all query values
  const getQueryValues = (obj: any) => {
    const isArray = _.isArray(obj);
    if (isArray) {
      obj.forEach((item: any) => {
        getQueryValues(item);
      });
    } else {
      for (const key in obj) {
        // eslint-disable-next-line security/detect-object-injection
        if (typeof obj[key] === 'object') {
          // eslint-disable-next-line security/detect-object-injection
          getQueryValues(obj[key]);
        } else if (
          // eslint-disable-next-line security/detect-object-injection
          typeof obj[key] === 'string' &&
          key === 'query'
        ) {
          // eslint-disable-next-line security/detect-object-injection
          uniqueQueries.add(obj[key]);
        }
      }
    }
  };

  if (!_.isEmpty(elasticSearchBodyObj?.query)) {
    getQueryValues(elasticSearchBodyObj?.query);
  }

  return uniqueQueries;
};

// ------------------------- functions for constructing elasticsearch query -------------------------

export function buildBaseQuery(fields: string[], value: string) {
  return new MultiMatchQuery(fields, value).type('best_fields').operator('and');
}

export function handleExactMatch(term: string, field: TextField) {
  const phrase = term.slice(1, -1); // Remove the quotes

  switch (field.reportType) {
    case 'Report':
      switch (field.operation) {
        case 'contains substring':
          return new MatchPhraseQuery('report', phrase).slop(0);
        case 'excludes substring':
          return new BoolQuery().mustNot(
            new MatchPhraseQuery('report', phrase).slop(0)
          );
        case 'contains substring (no negations)':
          return new MatchPhraseQuery('report_no_negations', phrase).slop(0);
        default:
          throw new Error(`Unsupported operation: ${field.operation}`);
      }
    case 'Impression':
      // This is not an exact match, but the same logic for impressions. Unfortunately, the query builder doesn't support exact matches for span queries.
      return spanNearQuery()
        .clauses([
          spanTermQuery('report', 'impression'),
          spanTermQuery('report', term),
        ])
        .slop(50) // Allow up to 50 words between the two terms
        .inOrder(true);
    default:
      throw new Error(`Unsupported report type: ${field.reportType}`);
  }
}

export function couldBeAnID(term: string): boolean {
  return term.length >= 7 && /^[0-9]+$/.test(term);
}

export function handleIDs(term: string) {
  const patientIdQuery = new WildcardQuery('patient_id', `*${term}`);
  const studyIdQuery = new WildcardQuery('study_id', `*${term}`);
  return {patientIdQuery: patientIdQuery, studyIdQuery: studyIdQuery};
}

export function handleSearchSpaceAndOperation(
  searchSpace: string,
  operation: string,
  term: string,
  orQuery: BoolQuery
) {
  if (searchSpace !== 'Report' && searchSpace !== 'Impression') {
    return orQuery;
  }
  if (
    operation !== 'contains substring' &&
    operation !== 'excludes substring' &&
    operation !== 'contains substring (no negations)'
  ) {
    return orQuery;
  }
  if (term.length === 0) {
    return orQuery;
  }
  if (searchSpace === 'Report') {
    switch (operation) {
      case 'contains substring':
        return orQuery.should(buildBaseQuery([], term));
      case 'excludes substring':
        return orQuery.mustNot(buildBaseQuery([], term));
      case 'contains substring (no negations)':
        return orQuery.should(buildBaseQuery(['report_no_negations'], term));
    }
  } else {
    const queryField =
      operation === 'contains substring (no negations)'
        ? 'report_no_negations'
        : 'report';
    const keywords = term.split(' ').filter(keyword => keyword.trim() !== '');
    const impressionQueries = keywords.map(keyword =>
      spanNearQuery()
        .clauses([
          spanTermQuery(queryField, 'impression'),
          spanTermQuery(queryField, keyword),
        ])
        .slop(50) // Allow up to 50 words between 'impression' and the subterm
        .inOrder(true)
    );
    switch (operation) {
      case 'contains substring':
        return impressionQueries.length > 1
          ? orQuery.must(impressionQueries) // multiple words need to be joined with AND
          : orQuery.should(impressionQueries); // otherwise it's a single word or a search query with a pipe |
      case 'excludes substring':
        return orQuery.mustNot(impressionQueries);
      case 'contains substring (no negations)':
        return impressionQueries.length > 1
          ? orQuery.must(impressionQueries) // multiple words need to be joined with AND
          : orQuery.should(impressionQueries); // otherwise it's a single word or a search query with a pipe |
    }
  }
}

export function buildElasticQuery(fields: TextField[]): object {
  const boolQuery = new BoolQuery();
  let hasAtLeastOneField = false;

  fields
    .map(
      (
        field // handle snomed terms
      ) =>
        !field.snomedTerms || field.snomedTerms.length === 0
          ? field
          : {
              ...field,
              value: field
                .snomedTerms!.map(term => `"${removeTextInParentheses(term)}"`)
                .join('|'),
            }
    )
    .filter(
      field =>
        field.value !== undefined &&
        field.value !== null &&
        field.value.trim() !== ''
    ) // filter out empty search boxes
    .forEach(field => {
      const {value, operation, reportType} = field;
      hasAtLeastOneField = true;

      // split on any pipes for OR | Operations
      const terms = value
        .split('|')
        .map(v => v.trim())
        .filter(v => v !== undefined && v !== null && v.trim() !== '');

      let orQuery = new BoolQuery();
      for (const term of terms) {
        // handle EXACT "" operations
        if (term.startsWith('"') && term.endsWith('"')) {
          orQuery.should(handleExactMatch(term, field));
        } else if (couldBeAnID(term)) {
          // handle ID searches
          const {patientIdQuery, studyIdQuery} = handleIDs(term);
          orQuery.should(patientIdQuery);
          orQuery.should(studyIdQuery);
        } else {
          orQuery = handleSearchSpaceAndOperation(
            reportType,
            operation,
            term,
            orQuery
          );
        }
      }
      boolQuery.must(orQuery); // Add the OR query to the current field query
    });

  if (!hasAtLeastOneField) {
    boolQuery.must(new BoolQuery());
  }

  const body = boolQuery.toJSON();
  return body;
}

// ------------------------- highlights search terms within split-screen report component -------------------------
export const highlightSearchterm = (searchTerms: Set<string>, text: string) => {
  let highlightedText = text;

  // Utility function to trim special characters from the beginning and end of a string
  const trimSpecialChars = (string: string) => {
    return string.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '');
  };

  // Utility function to escape special regex characters
  const escapeRegexChars = (string: string) => {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  // Convert the Set to an Array, escape each term, and then filter
  Array.from(searchTerms)
    .map(trimSpecialChars)
    .map(escapeRegexChars)
    .filter(term => term && term.trim() !== '')
    .forEach(searchTerm => {
      // eslint-disable-next-line security/detect-non-literal-regexp
      const regex = new RegExp(searchTerm, 'gi');
      highlightedText = highlightedText?.replace(
        regex,
        match => `<em>${match}</em>`
      );
    });

  return highlightedText;
};

// ------------------------- patient search functions -------------------------
export type PatientSortOption =
  | 'Exam date ASC'
  | 'Exam date DESC'
  | 'Body Part'
  | 'Modality';

const formatFilters = (filters: {field: string; values: any[]}[]): Filter[] => {
  const formattedFilters: Filter[] = [];
  for (const filter of filters) {
    formattedFilters.push({
      field: filter.field,
      type: 'any',
      values: filter.values,
    });
  }
  return formattedFilters;
};

export const formatFiltersAppSearch = (filters: any) => {
  const formattedFilters: any = {all: []};
  for (const filter of filters) {
    formattedFilters.all.push({
      any: [{[`${filter.field}`]: filter.values}],
    });
  }
  return formattedFilters;
};

export const fetchAllStudiesForListOfPatientIDs = ({
  patient_ids,
  connector,
  resultFields,
}: {
  patient_ids: string[];
  connector: APIConnector;
  resultFields: Record<string, FieldConfiguration>;
}) => {
  const req: RequestState = {
    searchTerm: '',
    filters: formatFilters([
      {field: 'patient_id.keyword', values: patient_ids},
    ]),
    resultsPerPage: 10000,
  };

  const queryConfig: QueryConfig = {
    ...req,
    facets: {},
    result_fields: resultFields,
  };
  return connector.onSearch(req, queryConfig);
};

export const intersperseAdditionalStudies = (
  results: any[],
  additionalPatientStudies: any[],
  sortPatientIDsBy: PatientSortOption
) => {
  const interspersedResults = [];
  const patientIds = _.uniq(
    results.map((result: any) => result['patient_id']?.['raw'])
  );

  for (const patientId of patientIds) {
    const patientResults = results.filter(
      (result: any) => result['patient_id']?.['raw'] === patientId
    );
    const patientAdditionalResults = additionalPatientStudies
      .map(study => {
        study['isAdditional'] = true;
        return study;
      })
      .filter((result: any) => result['patient_id']?.raw === patientId);
    interspersedResults.push(
      ..._.uniqBy(
        [...patientResults, ...patientAdditionalResults].sort(
          // Sort the results based on sortPatientIDsBy
          (reportA, reportB) => {
            switch (sortPatientIDsBy) {
              case 'Exam date ASC':
                return (
                  new Date(reportA['exam_date']?.['raw']).getTime() -
                  new Date(reportB['exam_date']?.['raw']).getTime()
                );
              case 'Exam date DESC':
                return (
                  new Date(reportB['exam_date']?.['raw']).getTime() -
                  new Date(reportA['exam_date']?.['raw']).getTime()
                );
              case 'Body Part':
                return reportA['body_part']?.['raw'].localeCompare(
                  reportB['body_part']?.['raw']
                );
              case 'Modality':
                return reportA['modality']?.['raw'].localeCompare(
                  reportB['modality']?.['raw']
                );
              default:
                return 0;
            }
          }
        ),
        result => result.study_id?.raw
      )
    );
  }

  return interspersedResults;
};

export const extractUniquePatientCount = (minervaResponse: any) => {
  return minervaResponse?.aggregations?.facet_bucket_all?.unique_patient_count
    ?.value;
};

export function removeSizeAndFrom(elasticRequestBody: string): string {
  let jsonObj;
  // Check if the input is a string and a valid JSON
  if (typeof elasticRequestBody === 'string') {
    try {
      jsonObj = JSON.parse(elasticRequestBody);
    } catch (error) {
      throw new Error(`Invalid JSON string: ${elasticRequestBody}`);
    }
  } else if (typeof elasticRequestBody === 'object') {
    jsonObj = elasticRequestBody; // Assuming it's already a JSON object
  } else {
    throw new Error(
      `Unexpected input type for removeSizeAndFrom: ${typeof elasticRequestBody}`
    );
  }
  if (jsonObj && typeof jsonObj === 'object') {
    if (jsonObj.from) {
      delete jsonObj.from;
    }
    if (jsonObj.size) {
      delete jsonObj.size;
    }
  }
  return jsonObj;
}

// ------------------------- search analytics functions -------------------------
export function trackSearchEvent(
  requestBody: any,
  resultCount: number,
  duration: number // New parameter
) {
  try {
    console.log('requestBody', requestBody);
    const res = parseElasticSearchBody(requestBody);
    console.log('res', res);
    const [
      textFields,
      filters,
      groupPatientsByID,
      sortPatientIDsBy,
      useVectorSearch,
    ] = parseElasticSearchBody(requestBody);

    const searchEvent = {
      searchBoxes: textFields,
      patientSearch: {
        isActive: groupPatientsByID,
        sortBy: sortPatientIDsBy,
      },
      filters: filters,
      useVectorSearch: useVectorSearch,
      resultCount: resultCount,
      duration: duration, // Include duration in the event
    };
    if (textFields.length || groupPatientsByID || filters.length) {
      trackEvent('SEARCH', searchEvent);
    }
  } catch (error) {
    console.error('Error parsing Elasticsearch body:', error);
  }
}
